import React from 'react';
import SectionTextBox from '../section-textbox/SectionTextBox';
import { Container } from '../customers/styles';
import * as S from './styles';

const ImageHolder = ({
	title,
	images,
	backgroundColor,
	wrapperMargin,
	imgHeight,
	imgWidth,
}) => {
	return (
		<S.Wrapper wrapperMargin={wrapperMargin} backgroundColor={backgroundColor}>
			<Container>
				{title && (
					<S.SectionWrapper>
						<SectionTextBox title={title} />
					</S.SectionWrapper>
				)}
				<S.LogoHolder>
					{images.map(image => (
						<S.ImageWrapper imgHeight={imgHeight} imgWidth={imgWidth}>
							<img src={image.file.url} alt={image.title} />

							<p>{image.title}</p>
							<span>{image.description}</span>
						</S.ImageWrapper>
					))}
				</S.LogoHolder>
			</Container>
		</S.Wrapper>
	);
};

export default ImageHolder;
