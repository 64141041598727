import { Link } from 'gatsby';
import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
} from '../../../utils/helpers';

export const GrayWrapper = styled.div`
	margin-top: 38px;
	padding-top: 105px;
	background-color: ${setColors.warmGray};
`;

export const WhiteWrapper = styled.div`
	margin-top: 100px;
	background-color: ${setColors.white};
`;

export const SecondTitle = styled.div`
	${({ theme }) => `${theme.marginAuto}; ${theme.textCenter}`};
	color: ${setColors.black};
	line-height: 58px;
	max-width: 800px;
	padding: 0 5px;
`;

export const SecondSubTitle = styled.div`
	${({ theme }) => `${theme.marginAuto}; ${theme.textCenter}`};
	color: ${setColors.black};
	max-width: 500px;
	font-style: normal;
	font-weight: normal;
	line-height: 25px;
	padding: 5px 5px 20px 5px;
	${mediaQuery.mobileXL`
			max-width: 350px;
	`}
`;

export const LearnMoreLink = styled(Link)`
	font-family: ${setFontFamily.book};
	font-style: normal;
	font-weight: 450;
	font-size: ${setFontSize.tiny};
	line-height: 23px;
	color: ${setColors.themeBlue};
	text-decoration: none;
	text-align: center;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 70px;
}
`;
