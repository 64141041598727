import styled from 'styled-components';
import {
	setColors,
	mediaQuery,
	SetContainerWidth,
	setFontFamily,
	setFontSize,
} from '../../../../utils/helpers';

export const Wrapper = styled.div`
	background-color: ${({ backgroundColor }) =>
		backgroundColor || setColors.warmGray};
	margin-top: ${({ wrapperMargin }) => wrapperMargin || '127px'};
	a {
		margin-top: 2rem;
		text-decoration: none;
		color: ${setColors.blue};
		display: flex;
		align-items: center;
		svg {
			margin-left: 0.5rem;
		}
	}
`;

export const SectionWrapper = styled.div`
	padding-top: 50px;
	${mediaQuery.mobileXL`padding-top: 35px;`}
`;

export const LogoHolder = styled.div`
	padding-top: 46px;
	display: grid;
	width: ${SetContainerWidth.fullWidth};
	grid-template-columns: repeat(3, 1fr);
	column-gap: 33px;
	row-gap: 60px;
	padding-bottom: 84px;

	${mediaQuery.tabletML`grid-template-columns: 1fr;`}
	${mediaQuery.mobileXL`row-gap: 40px;padding-bottom: 110px;`}
`;

export const ImageWrapper = styled.div`
	padding: 0 15px;
	img {
		height: ${({ imgHeight }) => imgHeight || '75px'};
		width: ${({ imgWidth }) => imgWidth || '75px'};
	}
	p {
		font-weight: 700;
		font-size: ${setFontSize.mediumSmall};
		line-height: 1.27;
		color: ${setColors.lightBlack};
		font-family: ${setFontFamily.bold};
		margin-top: 30px;
		margin-bottom: 10px;
		${mediaQuery.laptopXL`font-size: ${setFontSize.tinySmall};`}
		${mediaQuery.mobileXL`font-size: ${setFontSize.verySmall};margin-bottom: 13px;margin-top: 22px;`}
	}
	span {
		color: ${setColors.lightBlack};
		font-size: ${setFontSize.tiny};
		line-height: 1.5;
		font-family: ${setFontFamily.book};
		${mediaQuery.laptopXL`font-size: ${setFontSize.mediumTiny}`}
		${mediaQuery.tabletL`font-size: ${setFontSize.veryTiny}`}
		${mediaQuery.mobileXL`font-size: ${setFontSize.veryTiny}`}
	}
	${({ theme }) => theme.textCenter};
`;
