import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '../components/global/seo';
import Layout from '../components/global/layout/Layout';
import Button from '../components/global/Button';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import CTAWrapper from '../components/home-page/CtaWrapper/CTAWrapper';
import CTA from '../components/global/CTA/CTA';
import ToolkitImageHolder from '../components/global/image-holder/ImageHolder';
import { splitText } from '../../utils/helpers';
import * as S from '../components/bot-builder-page/styles';
import {
	GrayWrapper,
	WhiteWrapper,
	SecondTitle,
	SecondSubTitle,
	LearnMoreLink,
} from '../components/features/styles';
import { Container } from '../components/global/customers/styles';
import { IconNext } from '../components/global/icons/index';

const Features = ({ data }) => {
	const {
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
		seoJsonSchema,
		section1Title,
		section1Subtitle,
		section1ButtonText,
		section1ButtonLink,
		section2Image,
		section2AllFeatures,
		section2More,
		section3Image,
		section3AllFeatures,
		section3More,
		section4Image,
		section4AllFeatures,
		section4More,
		section5Image,
		section5AllFeatures,
		section5More,
		section6Image,
		section6AllFeatures,
		section6More,
		section7Image,
		section7AllFeatures,
		section7More,
		section8Title,
		section8Subtitle,
		section8AllFeatures,
		section8More,
		ctaTitle,
		ctaSubtitle,
	} = data.contentfulFeatures;
	return (
		<>
			<Layout>
				<SEO
					title={seoTitle}
					description={seoDescription}
					image={seoImage}
					article={seoArticle}
					seoJsonSchema={seoJsonSchema}
				/>
				<Container>
					<S.Title className="font-black-54">{section1Title}</S.Title>
					<S.SubSection>
						<p>{section1Subtitle}</p>
						<div>
							<Button as="a" href={section1ButtonLink}>
								{section1ButtonText}
							</Button>
						</div>
					</S.SubSection>
				</Container>
				<GrayWrapper>
					<SecondTitle className="font-black-46">
						{section2Image.title}
					</SecondTitle>
					<SecondSubTitle className="font-book-20">
						{section2Image.description}
					</SecondSubTitle>
					<S.ImageWrapper>
						<Img
							fluid={section2Image.fluid}
							alt={section2Image.title}
							imgStyle={{ objectFit: 'unset' }}
							style={{
								maxWidth: '927px',
								margin: '0 auto',
								maxHeight: '570px',
							}}
							fadeIn={false}
							loading="eager"
						/>
					</S.ImageWrapper>
					<ToolkitImageHolder
						wrapperMargin={'0px'}
						imgHeight={'33px'}
						imgWidth={'auto'}
						images={section2AllFeatures}
					/>
					<LearnMoreLink to={`${splitText(section2More, 1, '~')}`}>
						<>
							{splitText(section2More, 0, '~')}{' '}
							<IconNext
								style={{
									paddingLeft: '8px',
								}}
							/>
						</>
					</LearnMoreLink>
				</GrayWrapper>
				<WhiteWrapper>
					<SecondTitle className="font-black-46">
						{section3Image.title}
					</SecondTitle>
					<SecondSubTitle className="font-book-20">
						{section3Image.description}
					</SecondSubTitle>
					<S.ImageWrapper>
						<Img
							fluid={section3Image.fluid}
							alt={section3Image.title}
							imgStyle={{ objectFit: 'unset' }}
							style={{
								maxWidth: '927px',
								margin: '0 auto',
								maxHeight: '570px',
							}}
							fadeIn={false}
							loading="eager"
						/>
					</S.ImageWrapper>
					<ToolkitImageHolder
						backgroundColor={'white'}
						wrapperMargin={'0px'}
						imgHeight={'33px'}
						imgWidth={'auto'}
						images={section3AllFeatures}
					/>
					<LearnMoreLink to={`${splitText(section3More, 1, '~')}`}>
						<>
							{splitText(section3More, 0, '~')}{' '}
							<IconNext
								style={{
									paddingLeft: '8px',
								}}
							/>
						</>
					</LearnMoreLink>
				</WhiteWrapper>
				<GrayWrapper>
					<SecondTitle className="font-black-46">
						{section4Image.title}
					</SecondTitle>
					<SecondSubTitle className="font-book-20">
						{section4Image.description}
					</SecondSubTitle>
					<S.ImageWrapper>
						<Img
							fluid={section4Image.fluid}
							alt={section4Image.title}
							imgStyle={{ objectFit: 'unset' }}
							style={{
								maxWidth: '927px',
								margin: '0 auto',
								maxHeight: '570px',
							}}
							fadeIn={false}
							loading="eager"
						/>
					</S.ImageWrapper>
					<ToolkitImageHolder
						wrapperMargin={'0px'}
						imgHeight={'33px'}
						imgWidth={'auto'}
						images={section4AllFeatures}
					/>
					<LearnMoreLink to={`${splitText(section4More, 1, '~')}`}>
						<>
							{splitText(section4More, 0, '~')}{' '}
							<IconNext
								style={{
									paddingLeft: '8px',
								}}
							/>
						</>
					</LearnMoreLink>
				</GrayWrapper>
				<WhiteWrapper>
					<SecondTitle className="font-black-46">
						{section5Image.title}
					</SecondTitle>
					<SecondSubTitle className="font-book-20">
						{section5Image.description}
					</SecondSubTitle>
					<S.ImageWrapper>
						<Img
							fluid={section5Image.fluid}
							alt={section5Image.title}
							imgStyle={{ objectFit: 'unset' }}
							style={{
								maxWidth: '927px',
								margin: '0 auto',
								maxHeight: '570px',
							}}
							fadeIn={false}
							loading="eager"
						/>
					</S.ImageWrapper>
					<ToolkitImageHolder
						backgroundColor={'white'}
						wrapperMargin={'0px'}
						imgHeight={'33px'}
						imgWidth={'auto'}
						images={section5AllFeatures}
					/>
					<LearnMoreLink to={`${splitText(section5More, 1, '~')}`}>
						<>
							{splitText(section5More, 0, '~')}{' '}
							<IconNext
								style={{
									paddingLeft: '8px',
								}}
							/>
						</>
					</LearnMoreLink>
				</WhiteWrapper>
				<GrayWrapper>
					<SecondTitle className="font-black-46">
						{section6Image.title}
					</SecondTitle>
					<SecondSubTitle className="font-book-20">
						{section6Image.description}
					</SecondSubTitle>
					<S.ImageWrapper>
						<Img
							fluid={section6Image.fluid}
							alt={section6Image.title}
							imgStyle={{ objectFit: 'unset' }}
							style={{
								maxWidth: '927px',
								margin: '0 auto',
								maxHeight: '570px',
							}}
							fadeIn={false}
							loading="eager"
						/>
					</S.ImageWrapper>
					<ToolkitImageHolder
						wrapperMargin={'0px'}
						imgHeight={'33px'}
						imgWidth={'auto'}
						images={section6AllFeatures}
					/>
					<LearnMoreLink to={`${splitText(section6More, 1, '~')}`}>
						<>
							{splitText(section6More, 0, '~')}{' '}
							<IconNext
								style={{
									paddingLeft: '8px',
								}}
							/>
						</>
					</LearnMoreLink>
				</GrayWrapper>
				<WhiteWrapper>
					<SecondTitle className="font-black-46">
						{section7Image.title}
					</SecondTitle>
					<SecondSubTitle className="font-book-20">
						{section7Image.description}
					</SecondSubTitle>
					<S.ImageWrapper>
						<Img
							fluid={section7Image.fluid}
							alt={section7Image.title}
							imgStyle={{ objectFit: 'unset' }}
							style={{
								maxWidth: '927px',
								margin: '0 auto',
								maxHeight: '570px',
							}}
							fadeIn={false}
							loading="eager"
						/>
					</S.ImageWrapper>
					<ToolkitImageHolder
						backgroundColor={'white'}
						wrapperMargin={'0px'}
						imgHeight={'33px'}
						imgWidth={'auto'}
						images={section7AllFeatures}
					/>
					<LearnMoreLink to={`${splitText(section7More, 1, '~')}`}>
						<>
							{splitText(section7More, 0, '~')}{' '}
							<IconNext
								style={{
									paddingLeft: '8px',
								}}
							/>
						</>
					</LearnMoreLink>
				</WhiteWrapper>
				<GrayWrapper>
					<SecondTitle className="font-black-46">{section8Title}</SecondTitle>
					<SecondSubTitle className="font-book-20">
						{section8Subtitle}
					</SecondSubTitle>
					<ToolkitImageHolder
						wrapperMargin={'0px'}
						imgHeight={'33px'}
						imgWidth={'auto'}
						images={section8AllFeatures}
					/>
					<LearnMoreLink to={`${splitText(section8More, 1, '~')}`}>
						<>
							{splitText(section8More, 0, '~')}{' '}
							<IconNext
								style={{
									paddingLeft: '8px',
								}}
							/>
						</>
					</LearnMoreLink>
				</GrayWrapper>
				<WrapperOne disableBottomMargin="true">
					<CTAWrapper>
						<CTA title={ctaTitle} subtitle={ctaSubtitle} />
					</CTAWrapper>
				</WrapperOne>
			</Layout>
		</>
	);
};

export const query = graphql`
	{
		contentfulFeatures {
			seoTitle
			seoDescription
			seoArticle
			seoImage {
				title
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
			}
			seoJsonSchema {
				internal {
					content
				}
			}
			section1Title
			section1Subtitle
			section1ButtonText
			section1ButtonLink
			section2Image {
				fluid(maxWidth: 927, maxHeight: 570) {
					...GatsbyContentfulFluid
				}
				title
				description
			}
			section2AllFeatures {
				title
				description
				file {
					url
					contentType
				}
			}
			section2More
			section3Image {
				fluid(maxWidth: 927, maxHeight: 570) {
					...GatsbyContentfulFluid
				}
				title
				description
			}
			section3AllFeatures {
				title
				description
				file {
					url
					contentType
				}
			}
			section3More

			section4Image {
				fluid(maxWidth: 927, maxHeight: 570) {
					...GatsbyContentfulFluid
				}
				title
				description
			}
			section4AllFeatures {
				title
				description
				file {
					url
					contentType
				}
			}
			section4More
			section5Image {
				fluid(maxWidth: 927, maxHeight: 570) {
					...GatsbyContentfulFluid
				}
				title
				description
			}
			section5AllFeatures {
				title
				description
				file {
					url
					contentType
				}
			}
			section5More
			section6Image {
				fluid(maxWidth: 927, maxHeight: 570) {
					...GatsbyContentfulFluid
				}
				title
				description
			}
			section6AllFeatures {
				title
				description
				file {
					url
					contentType
				}
			}
			section6More
			section7Image {
				fluid(maxWidth: 927, maxHeight: 570) {
					...GatsbyContentfulFluid
				}
				title
				description
			}
			section7AllFeatures {
				title
				description
				file {
					url
					contentType
				}
			}
			section7More
			section8Title
			section8Subtitle
			section8AllFeatures {
				title
				description
				file {
					url
					contentType
				}
			}
			section8More
			ctaTitle
			ctaSubtitle
		}
	}
`;

export default Features;
